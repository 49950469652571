import React from 'react'
import './NewsCard.css'
import { truncate } from 'lodash'

const NewsCard = ({card}) => {
  return (
    <div className="flexColStart newsCardContainer">
      <img src={card?.thumbnail} alt="Thumbnail" />
      <h1 className="boldText">{card?.title}</h1>
      <p className='newsCardDes'>{truncate(card?.des, {length:"50"})}</p>
      <p className='newsCardCreateDate'>Created at {card?.date}</p>
    </div>
  )
}

export default NewsCard