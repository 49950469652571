import React, { useState } from "react";
import "./Header.css";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { getMenuStyles } from "../../Utils/common";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <section className="headerWrapper">
      <div className="flexCenter headerContainer">
        <div className="flexStart h-logo">
          <Link to={"/"}>
            <img
              src="./assets/images/elements/wbg-logo.png"
              alt="company logo"
              width={150}
              className="header-logo"
            />
          </Link>
        </div>
        <OutsideClickHandler
          onOutsideClick={() => {
            setMenuOpened(false);
          }}
        >
          <div className="flexCenter h-menu" style={getMenuStyles(menuOpened)}>
            <NavLink to={"/"}>Home</NavLink>
            <NavLink to={"/aboutus"}>About us</NavLink>
            <NavLink to={"/ourcompanies"}>Our Companies</NavLink>
            <NavLink to={"/news"}>News</NavLink>
            <NavLink to={"/certificates"}>Certificates</NavLink>
            <NavLink to={"/careers"}>Careers</NavLink>
            <NavLink to={"/contactus"}>Contact us</NavLink>
          </div>
        </OutsideClickHandler>

        <div
          className="menuBar"
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <FaBars size={25} />
        </div>
      </div>
    </section>
  );
};

export default Header;
