import React from "react";
import "./Footer.css";
import { FaClock, FaSquareInstagram } from "react-icons/fa6";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaLocationArrow,
  FaPhoneAlt,
  FaTelegramPlane,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footerWrapper">
      <div className="innerWidth flexCenter footerContainer">
        <div className="flexColCenter footer-left">
          <img
            src="./assets/images/elements/WBGroupOfCompanies.png"
            alt="Footer Logo"
            width={180}
          />
          <span>WorldBridge Group, Your Gateway to Cambodia.</span>
          <button className="footerButton">About Us</button>
        </div>
        <div className="flexColCenter footer-center">
          <span className="boldText">Head Office</span>
          <span className="flexStart footerCenterElements">
            <div className="flexEnd footercenterIcon">
              <FaLocationArrow size={18} fill="#12519C"/>
            </div>
            <div className="footerCenterDetail">
              The Bridge Soho Tower #38-25, National Assembly Street, Village
              14, Tonle Bassac, Chamkarmorn, Phnom Penh, Cambodia
            </div>
          </span>
          <span className="flexStart footerCenterElements">
            <div className="flexEnd footercenterIcon">
              <FaPhoneAlt size={18} fill="#12519C"/>
            </div>
            <div className="footerCenterDetail">+855 23 42 46 82</div>
          </span>
          <span className="boldText">Open Hour</span>
          <span className="flexStart footerCenterElements">
            <div className="flexEnd footercenterIcon">
              <FaClock size={20} fill="#12519C"/>
            </div>
            <div className="footerCenterDetail">
              Mon – Fri: 8am – 5:30pm, <br /> Saturday: 8am – 12pm,
              <br />
              Sunday: CLOSED
            </div>
          </span>
        </div>
        <div className="flexColCenter footer-right">
          <span className="boldText"> Social Media</span>
          <div className="flexStart footerRightMedia">
            <a href="https://www.facebook.com/worldbridgegroup" target="_blank" rel="noreferrer">
              <FaFacebookF size={20} fill="#12519C"/>
            </a>
            <a href="https://www.instagram.com/worldbridge_group/" target="_blank" rel="noreferrer">
              <FaSquareInstagram size={20} fill="#12519C"/>
            </a>
            <a href="https://www.linkedin.com/company/worldbridge-group" target="_blank" rel="noreferrer">
              <FaLinkedinIn size={20} fill="#12519C"/>
            </a>
            <a href="https://t.me/worldbridgegroupcareers" target="_blank" rel="noreferrer">
              <FaTelegramPlane size={20} fill="#12519C"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
