import React from "react";
import "./News.css";
import data from "../../Utils/News.json";
import NewsCard from "../NewsCard/NewsCard";

const News = () => {
  return (
    <section className="newsWrapper">
      <div className="divider"></div>
      <div className="flexCenter newsContainer">
        <div className="flexCenter newsTitle">
        <h1 className="boldText">News & Media</h1>
        </div>
        <div className="flexCenter newsCardElement">
          {data?.map((e, i) => (
            <NewsCard card={e} key={i}/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default News;
