import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './Layout/Layout';
import Home from './Pages/Home/Home';
import Aboutus from './Pages/AboutUs/Aboutus';
import NewsDetail from './Pages/NewsDetail/NewsDetail';
import Companies from './Pages/Companies/Companies';
import NewsPage from './Pages/NewsPage/NewsPage';
import Careers from './Pages/Careers/Careers';
import ContactUs from './Pages/ContactUs/ContactUs';
import Certificates from './Pages/Certificates/Certificates';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route element={<Layout/>}>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/aboutus" element={<Aboutus />}></Route>
          <Route path="/ourcompanies" element={<Companies />}></Route>
          <Route path="/news">
            <Route index  element={<NewsPage />}></Route>
            <Route path=':title' element={<NewsDetail />}></Route>
          </Route>
          <Route path="/certificates" element={<Certificates />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
