import React from "react";
import "./AboutHomePage.css";

const AboutHomePage = () => {
  return (
    <section className="aboutHomePageWrapper">
      <div className="flexCenter aboutHomePageContainer">
        <div className="flexColCenter aboutHomePageDetail">
          <h1 className="boldText">About Us</h1>
          <h3 className="boldText">We are dedicated to support you</h3>
          <p>
            WorldBridge opened our doors in 1992 and has since grown into a top
            choice for international partners in the Cambodian market. We have
            developed partnerships with major global brands and companies,
            bringing investment and contributing to economic growth in the
            Kingdom.
          </p>
        </div>
        
        <div className="flexCenter aboutHomePageWB">
          <div className="flexColCenter aboutHomePageWorld">
            <h1 className="boldText">WORLD</h1>
            <ul className="flexColStart aboutHomePageul">
              <li>W - Workability</li>
              <li>O - Outstanding Credibility</li>
              <li>R - Reliability</li>
              <li>L - Leader</li>
              <li>D - Dedicated and Dependable People</li>
            </ul>
          </div>
          <div className="vl"></div>
          <div className="flexColCenter aboutHomePageBridge">
            <h1 className="boldText">BRIDGE</h1>
            <ul className="flexColStart aboutHomePageul">
              <li>B - Broad-mindedness</li>
              <li>R - Responsibility</li>
              <li>I - Innovative Technology</li>
              <li>D - Delivering beyond expectations</li>
              <li>G - Global Connections</li>
              <li>E - Extensive Experience</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHomePage;
